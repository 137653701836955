import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'

const Materials = () => {
  const navigate = useNavigate()
  const [materialsData, setMaterialsData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('All')
  const isAuthenticated = useSelector(state => state.user)
  //   console.log('isAuthenticated', isAuthenticated)
  useEffect(() => {
    const fetchMaterialsData = async () => {
      try {
        if (!isAuthenticated?.isAuthenticated) {
          console.error('User information is not available.')
          return
        }

        const response = await axios.post('/api/module/getMaterials', {})

        const data = response.data

        if (response.status === 200) {
          console.log('data.data', data.data)
          setMaterialsData(data.data)
        } else {
          console.error('Failed to fetch materials data')
        }
      } catch (error) {
        console.error('Error fetching materials data:', error)
      }
    }

    fetchMaterialsData()
  }, [isAuthenticated]) // Include an empty dependency array to fetch materials only once when the component mounts

  const handleCategoryChange = category => {
    setSelectedCategory(category)
  }

  const uniqueCategories = Array.from(
    new Set(materialsData.map(material => material.category))
  )

  return (
    <div>
      <Header />
      <Title text="IMFeD Past Lectures" />

      <div className="container text-center">
        <p>
          IMFeD has been conducting training and educational CPD events for all
          healthcare professionals since the programme started in 2012. Over the
          years, we have had many credible speakers delivering insightful
          lectures with practical approaches, which also adds value to your
          paediatric practice. You can browse here to view lectures.
        </p>
      </div>
      <div className="d-flex justify-content-around mt-5 mx-5 flex-wrap">
        <p
          style={{
            cursor: 'pointer',
            color: selectedCategory === 'All' ? '#6ec1e4' : '#7a7a7a',
            fontWeight: '600'
          }}
          onClick={() => handleCategoryChange('All')}
        >
          All
        </p>
        {uniqueCategories.map((category, index) => (
          <p
            key={index}
            style={{
              cursor: 'pointer',
              color: selectedCategory === category ? '#6ec1e4' : '#7a7a7a',
              fontWeight: '600'
            }}
            className="ms-3"
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </p>
        ))}
      </div>
      <div className="cards-container mt-4 justify-content-center">
        {materialsData
          .filter(
            material =>
              selectedCategory === 'All' ||
              material.category === selectedCategory
          )
          .map((card, index) => (
            // <div key={index} className="card" style={{ backgroundImage: `url(${card.backgroundImage})`, height: '275px' }}>
            <img
              onClick={() =>
                navigate('/materialsInformation', { state: { id: card.id } })
              }
              key={index}
              src={card.imageURL}
              className="materials-img"
              alt="Materials"
            />
            // </div>
          ))}
      </div>

      <Footer />
    </div>
  )
}

export default Materials
