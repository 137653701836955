import React, { useState } from 'react'
import { Button, Modal, Checkbox, Form, Input } from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { saveToken, saveUser } from '../../redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import ForgotPassword from './forgotpassword'
import LegacyUser from './legacyUser'

const LoginModal = ({ isOpen, onClose, notClosable }) => {
  const handleOk = () => {
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false) // Add loading state
  const [isForgotModalOpen, setForgotModalOpen] = useState(false)
  const [isLegacyModalOpen, setLegacyModalOpen] = useState(false)
  const [email, setEmail] = useState('') // Add state for email
  const [form] = Form.useForm() // Ant Design form instance
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  })

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    localStorage.removeItem('imfed-token')
    localStorage.removeItem('imfed-user')
    try {
      const response = await axios.post('/api/auth/login', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      // console.log(response)

      // navigate("/home");
      if (response.data.user.legacy && !response.data.user.verified) {
        // Open the LegacyUser modal
        setLegacyModalOpen(true)
        setEmail(response.data.user.email)
        setIsLoading(false)
        handleCancel()
      } else {
        // Navigate to home if not a legacy user with unverified status
        localStorage.setItem('imfed-token', response.data.token)
        localStorage.setItem('imfed-user', response.data.user.username)
        dispatch(saveToken({ token: response.data.token }))
        dispatch(saveUser(response.data.user))
        window.location.reload()
        // Navigate to "/home" after refreshing
        navigate('/home')
      }

      // console.log(response.data)
    } catch (error) {
      setIsLoading(false)
      console.error('Error submitting form:', error)
      setError(error.response?.data.error || 'An error occurred')
    }
  }

  return (
    <div>
      <Modal
        title="Please Login"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={700}
        centered
        closable={notClosable ? false : true} // Hides the close (X) button
        maskClosable={notClosable ? false : true} // Prevents closing by clicking on the background
      >
        <div>
          <div
            style={{
              borderBottom: '1px solid #ddd',
              marginBottom: '15px',
              marginTop: '30px'
            }}
          ></div>

          <Form
            name="basic"
            style={{
              // maxWidth: 600,
              margin: 'auto', // Center horizontally
              marginTop: '50px', // Adjust the top margin to center vertically
              paddingLeft: '10%',
              paddingRight: '10%'
            }}
            initialValues={{
              remember: true
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            form={form}
          >
            {error && (
              <div
                style={{
                  color: 'red',
                  marginBottom: '10px',
                  textAlign: 'center'
                }}
              >
                {error}
              </div>
            )}
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*Username"
                value={formData.username}
                onChange={e => handleChange(e, 'username')}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password
                className="bottom-border-input"
                placeholder="*Password"
                value={formData.password}
                onChange={e => handleChange(e, 'password')}
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Checkbox>Remember me</Checkbox>
                <a
                  onClick={() => {
                    onClose()
                    setForgotModalOpen(true)
                  }}
                  style={{ cursor: 'pointer', color: '#703895' }}
                >
                  Lost your password
                </a>
              </div>
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading} // Use the loading prop to show/hide the loader
                  style={{
                    background: 'transparent',
                    color: '#703895',
                    border: '2px solid #703895',
                    width: '200px'
                  }}
                >
                  Login
                </Button>
              </div>
            </Form.Item>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p style={{ margin: 0, color: 'gray' }}>
                Don't have an account?{' '}
              </p>
              <span
                onClick={() => navigate('/register')}
                style={{
                  cursor: 'pointer',
                  marginLeft: '5px',
                  color: '#703895'
                }}
              >
                {' '}
                SIGNUP
              </span>
            </div>
          </Form>
        </div>
      </Modal>
      <ForgotPassword
        isOpen={isForgotModalOpen}
        onClose={() => setForgotModalOpen(false)}
      />
      <LegacyUser
        isOpen={isLegacyModalOpen}
        onClose={() => setLegacyModalOpen(false)}
        userEmail={email}
      />
    </div>
  )
}
export default LoginModal
